<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="Waybill Number"
              label-for="v-ref-no"
            >
              <b-form-input
                id="v-ref-no"
                v-model="filters['waybill_number']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div>
          <div
            class="m-2"
          >
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <ReportTableButtons
                  :items="rows"
                  :json_fieldz="json_fields"
                  :name="`${downloadReportName} - Page ${page}.xls`"
                  :bulkname="`${downloadReportName}.xls`"
                  :fetch="fetchWaybillForBranchNoPagination"
                  @refresh="fetchWaybillForBranch"
                />
              </b-col>
            </b-row>
          </div>
          <!-- table -->
          <b-overlay
            id="overlay-background"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <b-table
              :fields="fields"
              :items="rows"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              hover
              responsive
              striped
              show-empty
            >
              <template #empty>
                <TableDataFetching
                  :rows="rows"
                  :data-loading="dataLoading"
                />
              </template>
              <template #cell(waybill_number)="data">
                <div class="d-flex align-items-center">
                  <b-avatar
                    :text="avatarText(data.value)"
                    size="32"
                    variant="light-primary"
                  />
                  <div>
                    <div
                      class="font-weight-bolder ml-1 text-primary"
                      @click="onClickViewOrder(data.item.id)"
                    >
                      {{ data.value }}
                    </div>
                    <div class="font-small-2 ml-1">
                      {{ __dateTimeFormatter(data.item.created_at) }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(customer_name)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bold">
                      {{ data.value }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(customer_email)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ data.value }}
                    </div>
                    <div class="font-small-2">
                      <div><b>Primary number: </b>{{ data.item.customer_phone }}</div>
                    </div>
                    <div
                      v-if="data.item.customer_secondary_phone !== null"
                      class="font-small-2"
                    >
                      <div><b>Second number: </b>{{ data.item.customer_secondary_phone }}</div>
                    </div>
                    <div class="font-small-2">
                      {{ data.item.customer_address }}
                    </div>
                  </div>
                </div>
              </template>
              <template #cell(order_current_status.key)="data">
                <order-status-badge :status-key="data.value" />
              </template>
              <template #cell(advance)="data">
                {{ data.value || '---' }}
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row
                cols="12"
              >
                <b-col
                  class="d-flex align-items-center justify-content-sm-start"
                  md="3"
                >
                  <label class="width-75">Per page</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="pageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block width-100"
                  />
                </b-col>
                <!-- Pagination -->
                <b-col
                  class="d-flex align-items-center justify-content-sm-end"
                  md="9"
                >
                  <span class="text-muted pagination-text mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                  <b-pagination
                    v-model="page"
                    :per-page="perPage"
                    :total-rows="(meta.total)? meta.total : 0"
                    class="mb-0 mt-1 mt-sm-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
          </b-overlay>
        </div>
      </b-card>
    </div>
    <view-order-modal ref="viewOrderModal" />
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BCol,
  BFormGroup,
  // BForm,
  BFormInput,
  BPagination,
  BRow,
  // BModal,
  BOverlay,
  VBModal,
  BTable,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import FilterButtons from '@/components/Filter/FilterButtons.vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import ViewOrderModal from '@/components/AllOrders/ViewOrderModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import store from '@/store/index'

const FinanceRepository = RepositoryFactory.get('finance')

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCardActions,
    OrderStatusBadge,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    // BForm,
    BCard,
    BRow,
    BCol,
    BTable,
    // BModal,
    BOverlay,
    'v-select': vSelect,
    ViewOrderModal,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      id: null,
      avatarText,
      filters: {},
      total: 0,
      meta: {},
      dataLoading: false,
      // Start BS Table
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      form: {},
      showUpdateModal: false,
      modelLoading: false,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      // End BS Table
      pageLength: 20,
      dir: false,
      fields: [
        {
          key: 'waybill_number',
          label: 'Waybill Number',
          sortable: true,
          tdClass: 'text-left',
        },
        {
          key: 'customer_name',
          label: 'Customer',
          sortable: false,
        },
        {
          key: 'customer_email',
          label: 'Customer Contact',
          sortable: false,
        },
        {
          key: 'advance',
          label: 'Advance',
          sortable: false,
        },
        {
          key: 'cod',
          label: 'COD',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'collected_cod',
          label: 'Collected COD',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'delivery_charge',
          label: 'Delivery Charge',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'weight',
          label: 'weight',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'order_current_status.key',
          label: 'Status',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      json_fields: {
        'Waybill Number': 'waybill_number',
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        'Customer Address': {
          field: 'customer_address',
          callback: value => `"${value}"`, // Wrap the address in double quotes
        },
        'Customer Phone': 'customer_phone',
        'Second Phone Number': 'customer_secondary_phone',
        Advance: 'advance',
        COD: 'cod',
        'Collected Cod': 'collected_cod',
        'Delivery Charge': 'delivery_charge',
        Weight: 'weight',
        Status: {
          field: 'order_current_status.name',
          callback: value => `"${value}"`,
        },
      },
      downloadReportName: 'Branch-Orders',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchWaybillForBranch()
      },
    },
    deep: true,
    page() {
      this.fetchWaybillForBranch()
    },
    perPage() {
      this.fetchWaybillForBranch()
    },
  },
  mounted() {
    // Set the initial number of items
    this.fetchWaybillForBranch()
  },
  created() {
    this.id = this.$route.params.id
  },
  methods: {
    async fetchWaybillForBranch() {
      this.dataLoading = true
      this.rows = []
      try {
        const { data } = (await FinanceRepository.getAllBranchOrdersList(this.page, this.perPage, this.id, this.filterQuery))
        this.rows = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchWaybillForBranchNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await FinanceRepository.getAllBranchOrdersListNoPagination(this.id, this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    resetModal() {
      this.clearOnClick()
    },
    handleChangePage(page) {
      this.page = page
      this.fetchWaybillForBranch()
    },
    applyFilters() {
      this.fetchWaybillForBranch()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchWaybillForBranch()
      })
    },
    changePage(value) {
      this.perPage = value
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onClickViewOrder(id) {
      this.$refs.viewOrderModal.openModal(id)
    },
  },
}
</script>
<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select.scss';
@media (max-width: 767px) {
  .pagination-text {
    font-size: 0.75rem;
  }
}
</style>
